@import "designSystem/colors.scss";

.chats-wrapper {

    margin: 30px;
    display: flex;
    flex-direction: row;
    height: calc(100vh - 140px);
    position: fixed;
    width: calc(100vw - 310px);
    box-sizing: border-box;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,.06),0 2px 5px 0 rgba(0,0,0,.2);

    .chats-contacts{
        flex: 30%;
        border-right: 0.55px solid #c4c4c486;
        padding: 30px;
        .section-title{
            //padding-bottom: 10px;
            margin-bottom: 10px;
        }
        .chat-card{
            &:hover {
                opacity: 0.7;
                cursor: pointer;
            }
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            height: 120px;
            padding: 20px;
            background:white;
            border: 0.55px solid #c4c4c486;
            box-sizing: border-box;
            border-radius: 10px;
            margin-bottom: 30px;
            &.notification{
                background: rgba(188, 210, 81, 0.1);
                border: 0.55px solid #BCD251;
            }
            .text-container{
                height: 100%;
                padding: 10px;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: space-between;
                .message-timestamp{
                    text-align: right;
                    width: 80px;
                    font-weight: 500;
                    font-size: 13px;
                    color: #737581;
                }
            }
            .message-container {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                color: #737581;
                .message-content {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
                .message-date {
                    margin-left: 5px;
                }
                .message-date::before {
                    content: "- ";
                }
            }
            .notification-container {
                position: absolute;
                right: 10px;
                top: 10px;
                .notification{
                    background: #A23436;
                    height: 24px;
                    width: 24px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 12px;
                    font-size: 11px;
                    color: white
                }
            }
            .photo-text-container{
                display: flex;
                flex-direction: row;
                align-items: center;
                //height: 100%;
                min-width: 0;
                .photo{
                    min-width: 70px;
                    width: 70px;
                    height: 70px;
                    border-radius: 35px;
                    overflow: hidden;
                    align-items: center;
                    justify-content: center;
                    object-fit: cover;
                }
                .text{
                    margin-left: 10px;
                    padding: 10px;
                    display: flex;
                    height: 100%;
                    flex-direction: column;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    .name{
                        text-overflow: ellipsis;
                        white-space:nowrap;
                        overflow: hidden;
                        margin-top: 10px;
                        margin-bottom: 10px;
                        font-weight: bold;
                    }
                    .message{
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        color: #737581;
                    }
                }

            }

        }
        .chats-selector {
            margin-bottom: 10px;
            .MuiButtonBase-root {
                min-height: 0;
                font-size: 12x;
                padding: 10px;
                min-width: 0;
                font-weight: 700;
            }
            .MuiTabs-scrollButtons {
                padding: 0;
            }
            .MuiTabs-indicator {
                background-color: #A1C859;
            }
            .Mui-selected {
                color: #A1C859;
            }
            .MuiTabs-root {
                min-height: 0;
            }
        }
    }
    .chats {
        flex: 70%;
        display: flex;
        flex-direction: column;
        .progress-container {   
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .name-container{
            flex: none;
            order: 1;
            display: flex;
            flex-direction: row;
            background: #FFFFFF;
            border-bottom: 0.55px solid #c4c4c486;
            align-items: center;
            padding-left: 40px;
            padding-top: 20px;
            padding-bottom: 20px;

            .photo{
                width: 70px;
                height: 70px;
                border-radius: 35px;
                overflow: hidden;
                align-items: center;
                justify-content: center;
                object-fit: cover;
            }
            .name{
                font-weight: 600;
                font-size: 19px;
                margin-left: 20px;

            }
        }
        .message{
            display: flex;
            align-items: center;
            padding-left: 40px;
            // padding-top: 25px;
            // padding-bottom: 25px;
            // height: 50px;
            &.isUser{
                display: flex;
                justify-content: flex-end;
                padding-right: 40px;
            }
            .photo{
                width: 40px;
                height: 40px;
                border-radius: 20px;
                overflow: hidden;
                align-items: center;
                justify-content: center;
                object-fit: cover;
                &.isUser{
                    margin-left: 20px;
                }
            }
            .text-container{
                padding-left: 20px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;
                .message-text{
                    background: #FFFFFF;
                    border: 0.75px solid rgba(115, 117, 129, 0.15);
                    box-sizing: border-box;
                    //padding: 15px;
                    border-radius: 10px;

                    .message-reply-to {
                        .message-reply-who {
                            font-size: 12px;
                            font-weight: 600;
                            margin-bottom: 10px;
                            margin-right: 20px;
                        }
                        .message-reply-content {
                            font-size: 12px;
                            background-color: #dee2e6;
                            border-left: solid 3px #a23436;
                            padding: 5px;
                            color: #000000;
                            margin-bottom: 10px;
                        }
                    }

                    .menu-container {
                        position: relative;
                        .arrow {
                            position: absolute;
                            top: 0;
                            right: 0;
                            visibility: hidden;
                        }
                        &:hover {
                            .arrow {
                                visibility: visible;
                            }
                            cursor: pointer;
                        }
                        .file-link, 
                        .message-line{
                            padding-right: 20px;
                        }
                        .audio-player {
                            padding-right: 30px;
                            .MuiPaper-root{
                                background-color: transparent;
                            }
                        }
                    }

                    &.isUser{
                        border: none;
                        color: #FFFFFF;
                        background: #A1C859;
                    }
                }
                .message-timestamp{
                    margin-top: 3px;
                    font-weight: 600;
                    font-size: 11px;
                    color: #C4C4C4;
                    &.isUser{
                        display: flex;
                        justify-content: flex-end;
                    }
                }
            }
        }
        .message-container {
            overflow-y: auto;
            flex: 1 1;
            flex-basis: 0;
            order: 2;
        }
        .reply-input {
            flex: none;
            display: flex;
            order: 3;
            flex-direction: column;
            padding: 10px;

            .reply-row {       
                flex-direction: row;
                gap: 5px;
                display: flex;
            }
            .reply-to {
                font-size: 14px;
            }
            .reply-icon {
                &:hover {
                    cursor: pointer;
                }
            }
            .reply-message {
                
                font-size: 12px;
                background-color: #dee2e6;
                border-left: solid 3px rgb(162, 52, 54);
                padding: 5px;
                /* flex-shrink: 1; */
                margin: 10px;
                flex-grow: 0;
                /* flex-grow: 0; */

            }
        }
        .message-input{
            background: #FFFFFF;
            flex: none;
            order: 4;
            display: flex;
            justify-content: space-between;
            padding: 20px;
            margin-top: auto;
            border-top: 0.55px solid  #c4c4c486;

            .input{
                width: 100%;
                border: none;
                font-size: 15px;
                margin-right: 20px;
                margin-left: 20px;
                padding: 10px;

            }
            .icon{
                &:hover {
                    opacity: 0.7;
                    cursor: pointer;
                }
                &.disabled{
                    opacity: 0.5;
                    cursor: default;
                }
                width: 60px;
                margin-right: 20px;
            }


        }
    }
}

.forward-menu {
    padding: 10px 25px;
    h1 {
        font-size: 1.5rem;
    }
    h2 {
        margin-top: 20px;
        font-size: 1rem;
    }
    .MuiListItem-root:hover {
        cursor: pointer;
    }
}