.chat-bubble {
    img {
        max-height: 540px;
        max-width: 330px;
    }

    .message-text {
        padding: 15px;

            a {
                color: #212529;
                text-decoration: none;

                &:hover {
                    color: #212529;
                    text-decoration: underline;
                }
            }

        &.isUser {
            a {
                color: white;

                &:hover {
                    color: white;
                }
            }
        }
        .download-text {
            margin-left: 10px;
            margin-right: 10px;
        }
        .message-author {
            font-size: 12px;
            color: #A23436;
            font-weight: bold;
            margin-top: -5px;
            margin-bottom: 10px;
        }
    }

    &.bubble-image {
        &:hover {
            cursor: pointer;
        }
        .message-text {
            padding: 0;
        }
    }
}

