@import "designSystem/colors.scss";

.chat-file-uploader {
     border: 0;
     margin: auto 10px;
     &:hover {
          cursor: pointer;
     }
}

.chat-file-uploader-label {
     margin: auto;
}